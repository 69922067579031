.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.table-bg-info-light thead {
  background-color: #b2dbf5;
}
.table-tbody-sm tbody {
  font-size: 0.7rem;
}
.fw-100 {
  font-weight: 100;
}
.modal-header-text-primary h5 {
  color: $primary !important;
}
.nav-bg-info {
  background-color: $cyan-100;
}
.nav-pills .nav-partner.active,
.nav-pills .show > .nav-partner {
  color: $white;
  background-color: $menu-active-link;
}
.nav-pills .nav-partner {
  border-radius: 0;
}
.wh-3rem {
  width: 3rem;
  height: 3rem;
}
.bg-blue-1 {
  background-color: #c7dff0;
}
.bg-red-1 {
  background-color: #e078ab;
}
.bg-blue-2 {
  background-color: #96bedc;
}
.card-shadow--animation {
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  transition: all 0.5s;
}
.card-shadow--animation:hover {
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.15) !important;
}
.bg-bronce {
  background-color: $bronce;
}
.bg-platino {
  background-color: $gray-500;
}
.top-canvas {
  border-bottom: 1px solid #12263f08;
}
.top-canvas h5 {
  text-align: center;
  color: $primary;
  width: 100%;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #c2e6d9;
  color: #1c7152;
}
.Toastify__toast--success .Toastify__close-button {
  color: #1c7152;
}
.container-scroller {
  max-width: 100%;
}
.tabs-scroller {
  display: flex;
}
.container-scroller {
  display: flex;
}
.scroller-arrows {
  display: flex;
}
.tabs-scroller {
  overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
}
.btn-scroller {
  padding: 5px 10px;
  border: 0;
  background: transparent;
}
.tabs-scroller .btn-scroller.active {
  color: $primary;
}
.btn-arrows {
  border: 0;
  background-color: #fdfdfd;
  display: flex;
  align-items: center !important;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 3px;
}
.btn-arrows i {
  line-height: 1;
}
.fs-06 {
  font-size: 0.6rem;
}
.fs-08 {
  font-size: 0.8rem;
}
.text-pink-primary {
  color: $pink-primary;
}
.btn-pink-primary {
  color: $white;
  background-color: $pink-primary;
  border-color: $pink-primary;
}
.btn-pink-primary:hover,
.btn-pink-primary:focus {
  background-color: $pink-primary-dark;
  border-color: $pink-primary-dark;
  box-shadow: 0 0 0 0.15rem rgba(208, 34, 118, 43%);
}
.line-break {
  border-top: 1px solid #ccc;
  width: 100%;
}

.invalid-tooltip {
  position: initial !important;
  display: block;
}
.cursor-pointer {
  cursor: pointer;
}
.paginate-margin-ul-none ul {
  margin-bottom: 0;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
}
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px;
}
.bg-attention {
  background-color: $attention;
}
.text-attention-dark {
  color: $attention-dark;
}
.ps-125rem {
  padding-left: 1.25rem;
}
.img-adjust-socio {
  width: 72px;
  height: 72px;
  object-fit: contain;
}
.card-img-max-height {
  max-height: 170px;
}
.firstImageStyle {
    cursor: pointer;
    object-fit: fill; 
    width: 100%;
    height: 230px
}
.smallItemStyles{
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  max-height: 100%;
}
.slider-items .slick-current .item {
  background-color: #34c38f;
  border: 1px solid #34c38f !important;
  color: white !important;
}

.slider-items .slick-arrow {
  color: #999;
}